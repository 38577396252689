import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { BoldText } from '../../components/bold-text/bold-text';
import { InlineCode } from '../../components/inline-code/inline-code';
import { ItalicText } from '../../components/italic-text/italic-text';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Alerts are a `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/ARIA/apg/patterns/alert/"
      }}>{`common pattern`}</a>{` on the web. They show an important
message in a way that attracts the user's attention without interrupting the current workflow.
For example: `}<ItalicText mdxType="ItalicText">{`“Item was added to your cart”`}</ItalicText>{`,
or `}<ItalicText mdxType="ItalicText">{`“Login has failed: Check username/password”`}</ItalicText>{`.`}</p>
    <p>{`Creating accessible and nicely animated alerts will become a lot easier in the near future, thanks to the new
`}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/API/Popover_API"
      }}>{`Popover API`}</a>{` and new capabilities of CSS transitions.
I've prepared a demo that you can try out.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMCBAX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAFqb2WPJh//xAAaEAACAgMAAAAAAAAAAAAAAAABEQACECEj/9oACAEBAAEFAjS0e3HzBx//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAbEAACAQUAAAAAAAAAAAAAAAAAEAESMUFRcf/aAAgBAQAGPwKrGiSy6v/EABsQAAIDAAMAAAAAAAAAAAAAAAERACExEEGR/9oACAEBAAE/IS0HhsUNYvIC7KE3VVDWy+H/2gAMAwEAAgADAAAAEIAP/8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQMBAT8QJ//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAECAQE/EGf/xAAbEAEAAwEBAQEAAAAAAAAAAAABABEhMUGBsf/aAAgBAQABPxAdPPAX78ggAAB7SR11tzPIw7UdN8yLRobf7KrDyf/Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Man speaking into a megaphone",
          "title": "Man speaking into a megaphone",
          "src": "/static/8fcaebdccf527293c6aa934e152754c7/e5166/pexels-thirdman-megaphone.jpg",
          "srcSet": ["/static/8fcaebdccf527293c6aa934e152754c7/f93b5/pexels-thirdman-megaphone.jpg 300w", "/static/8fcaebdccf527293c6aa934e152754c7/b4294/pexels-thirdman-megaphone.jpg 600w", "/static/8fcaebdccf527293c6aa934e152754c7/e5166/pexels-thirdman-megaphone.jpg 1200w", "/static/8fcaebdccf527293c6aa934e152754c7/b17f8/pexels-thirdman-megaphone.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Photo: © Thirdman / pexels.com`}</em></p>
    <p>{`If you're not familiar with the Popover API, go read my article
`}<a parentName="p" {...{
        "href": "/popover-api-accessibility.en/"
      }}>{`“Make your content pop with the Popover API and CSS Anchor Positioning”`}</a>{` first.
I won't repeat the basics of the API and instead focus on the relevant parts of this specific use case.`}</p>
    <h2>{`Accessible Alerts Demo`}</h2>
    <p>{`Here's my demo of accessible alerts using the Popover API. You can adapt the message as well as the duration
and position of the alert. Give it a try:`}</p>
    <iframe src="https://alexlehner86.github.io/accessible-popover-alert/" title="Accessible Alerts Demo" loading="lazy"></iframe>
    <p>{`The alert appears above all other elements of the page and is automatically announced by screen readers.
I've tested this behavior with `}<a parentName="p" {...{
        "href": "https://www.freedomscientific.com/products/software/jaws/"
      }}>{`JAWS`}</a>{`,
`}<a parentName="p" {...{
        "href": "https://www.nvaccess.org/download/"
      }}>{`NVDA`}</a>{`, `}<a parentName="p" {...{
        "href": "https://support.google.com/accessibility/android/answer/6283677?hl=en"
      }}>{`TalkBack`}</a>{`
and `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/VoiceOver"
      }}>{`VoiceOver`}</a>{`. The alert doesn't interrupt the user's current task
and disappears after some time.`}</p>
    <h2>{`Implementation Details`}</h2>
    <p>{`Now let's examine the different building blocks that I used to create the accessible, animated alerts.`}</p>
    <h3>{`Generating dynamic HTML`}</h3>
    <p>{`I've implemented an `}<a parentName="p" {...{
        "href": "https://angular.io/guide/architecture-services"
      }}>{`Angular service`}</a>{` that dynamically generates
the popover element using JavaScript methods like `}<InlineCode mdxType="InlineCode">{`createElement()`}</InlineCode>{`
and `}<InlineCode mdxType="InlineCode">{`appendChild()`}</InlineCode>{`. Check out the `}<a parentName="p" {...{
        "href": "https://github.com/alexlehner86/accessible-popover-alert/blob/main/src/app/services/alert.service.ts"
      }}>{`demo code`}</a>{`
for more details. You don't need to be familiar with the Angular framework to understand the important parts.`}</p>
    <p>{`The following HTML code is appended to a container at the end of the body element:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<div popover="manual" role="alert" class="alert top-right">
    This is a very important message
</div>`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`The `}<InlineCode mdxType="InlineCode">{`popover`}</InlineCode>{` attribute turns the `}<InlineCode mdxType="InlineCode">{`div`}</InlineCode>{` element into a popover that
is initially hidden by the browser. We show the popover alert by calling the element's
`}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/showPopover"
      }}>{`showPopover() method`}</a>{`.`}</p>
    <p>{`Setting `}<InlineCode mdxType="InlineCode">{`popover="manual"`}</InlineCode>{` on the element defines a manual popover state. This prevents
light-dismiss, meaning, interacting with other elements on the page won't close the popover. We close the popover
programmatically using the `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/hidePopover"
      }}>{`hidePopover() method`}</a>{`.`}</p>
    <h3>{`Semantic Markup`}</h3>
    <p>{`Set `}<InlineCode mdxType="InlineCode">{`role="alert"`}</InlineCode>{` on the popover element to define it as an alert. The document
`}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/wai-aria-1.2/#alert"
      }}>{`Accessible Rich Internet Applications (WAI-ARIA) 1.2`}</a>{`
describes the alert role as follows:`}</p>
    <blockquote>
      <p parentName="blockquote">{`A type of `}<BoldText mdxType="BoldText">{`live region`}</BoldText>{` with important, and usually time-sensitive, information. `}{`[...]`}{` Alerts are
assertive live regions, which means `}<BoldText mdxType="BoldText">{`they cause immediate notification`}</BoldText>{` for assistive technology users.`}</p>
    </blockquote>
    <p>{`In general, we use `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Live_Regions"
      }}>{`ARIA live regions`}</a>{`
to communicate dynamic content changes. Setting `}<InlineCode mdxType="InlineCode">{`role="alert"`}</InlineCode>{` is actually equivalent to
setting `}<InlineCode mdxType="InlineCode">{`aria-live="assertive"`}</InlineCode>{` and `}<InlineCode mdxType="InlineCode">{`aria-atomic="true"`}</InlineCode>{`. This means, as
soon as our popover alert is displayed, screen readers immediately announce the alert's message.`}</p>
    <h3>{`Animation`}</h3>
    <p>{`Now let's put the icing on the cake. Let's add some animation to the popover element. There's only one problem: Popovers
are initially hidden via `}<InlineCode mdxType="InlineCode">{`display: none`}</InlineCode>{`. What a nightmare! This creates two obstacles:`}</p>
    <ol>
      <li parentName="ol">{`An element with the `}<InlineCode mdxType="InlineCode">{`display`}</InlineCode>{` property value of `}<InlineCode mdxType="InlineCode">{`none`}</InlineCode>{` is not rendered.
Therefore, it has no computed values for the different CSS properties like, e.g., `}<InlineCode mdxType="InlineCode">{`opacity`}</InlineCode>{`. If there
is no computed value, you have no starting point for the transition.`}</li>
      <li parentName="ol">{`The same problem exists the other way around: When the `}<InlineCode mdxType="InlineCode">{`display`}</InlineCode>{` property is set to `}<InlineCode mdxType="InlineCode">{`none`}</InlineCode>{`,
it immediately takes the element out of the flow of the rendered HTML document without triggering the transition effect.`}</li>
    </ol>
    <p>{`Lucky for us, the `}<a parentName="p" {...{
        "href": "https://drafts.csswg.org/css-transitions-2/"
      }}>{`CSS Transitions Level 2`}</a>{` specification adds new capabilities
to CSS transitions. It defines the new `}<InlineCode mdxType="InlineCode">{`@starting-style`}</InlineCode>{` rule:`}</p>
    <blockquote>
      <p parentName="blockquote">{`The `}<BoldText mdxType="BoldText">{`@starting-style rule`}</BoldText>{` is a grouping rule. The style rules inside it are used to establish styles to
transition from, if the previous style change event did not establish a before-change style for the element whose styles are
being computed.`}</p>
    </blockquote>
    <p>{`This allows us to define a starting state for our transition when the display type changes from `}<InlineCode mdxType="InlineCode">{`none`}</InlineCode>{`
to another type.`}</p>
    <p>{`The second piece of the puzzle is the new `}<InlineCode mdxType="InlineCode">{`transition-behavior`}</InlineCode>{` property and
its `}<InlineCode mdxType="InlineCode">{`allow-discrete`}</InlineCode>{` value:`}</p>
    <blockquote>
      <p parentName="blockquote">{`The `}<BoldText mdxType="BoldText">{`transition-behavior`}</BoldText>{` property specifies whether transitions will be started or not for discrete properties.`}</p>
    </blockquote>
    <p>{`Discrete properties have values that can't be interpolated. This is the case, e.g., for the `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/CSS/display"
      }}>{`display`}</a>{`
and `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/CSS/overlay"
      }}>{`overlay`}</a>{` properties. To allow transitions for them, we need to
set the `}<InlineCode mdxType="InlineCode">{`allow-discrete`}</InlineCode>{` value. You can also include
the `}<InlineCode mdxType="InlineCode">{`transition-behavior`}</InlineCode>{` value as part of a shorthand `}<InlineCode mdxType="InlineCode">{`transition`}</InlineCode>{` declaration.`}</p>
    <p>{`How does it all fit together? My demo includes the "top-right" position, where the alert fades in and slides in from the right.
Here's the CSS code for the transition:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`.alert[popover].top-right {
    /* Final state of the exit animation */
    opacity: 0;
    transform: translateX(100%);

    transition: opacity 0.5s,
        transform 0.5s,
        overlay 0.5s allow-discrete,
        display 0.5s allow-discrete;
}

.alert[popover].top-right:popover-open {
    /* Final state of the show animation */
    opacity: 1;
    transform: translateX(0);
}

/* Needs to be after the previous [popover]:popover-open rule to take effect, as the specificity is the same */
@starting-style {
    .alert[popover].top-right:popover-open {
        opacity: 0;
        transform: translateX(100%);
    }
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`In the example above, the `}<InlineCode mdxType="InlineCode">{`opacity`}</InlineCode>{` and `}<InlineCode mdxType="InlineCode">{`transform`}</InlineCode>{` properties
are transitioned for a duration of half a second. We want the browser to show the transitioned content and keep the
popover in the `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Glossary/Top_layer"
      }}>{`top layer`}</a>{` for the entire animation duration.
To achieve this, we also include `}<InlineCode mdxType="InlineCode">{`overlay 0.5s allow-discrete`}</InlineCode>{`
and `}<InlineCode mdxType="InlineCode">{`display 0.5s allow-discrete`}</InlineCode>{` in the transition.`}</p>
    <p>{`Warning: These features are experimental and might change in the future!`}</p>
    <h2>{`Browser Support`}</h2>
    <p>{`The `}<InlineCode mdxType="InlineCode">{`popover`}</InlineCode>{` attribute is already `}<a parentName="p" {...{
        "href": "https://caniuse.com/mdn-api_htmlelement_popover"
      }}>{`supported in all major browsers`}</a>{`.
At the time of writing this article, Firefox had only enabled the feature in its experimental Nightly builds. But I expect
Firefox to release the feature soon.`}</p>
    <p>{`The new transition capabilities are only supported by `}<a parentName="p" {...{
        "href": "https://caniuse.com/mdn-css_properties_transition-behavior"
      }}>{`Chrome and Edge`}</a>{`
at the moment. I'm hopeful that they'll be part of `}<a parentName="p" {...{
        "href": "https://github.com/web-platform-tests/interop/tree/main/2024"
      }}>{`Interop 2024`}</a>{`
and reach cross-browser compatibility until the end of the year.`}</p>
    <h2>{`Useful Resources`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/WAI/ARIA/apg/patterns/alert/"
        }}>{`Alert Pattern (ARIA Authoring Practices Guide)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/API/Popover_API"
        }}>{`Popover API (MDN)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://drafts.csswg.org/css-transitions-2/"
        }}>{`CSS Transitions Level 2`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      